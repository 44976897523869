import React, { Component } from 'react';


class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
        };
    }

    toggleInfo = () => {
        this.setState(prevState => ({
            showInfo: !prevState.showInfo,
        }));
    };

    render() {
        const cardStyle = {
            position: 'relative',
            display: 'inline-block',

        };

        const iconStyle = {
            borderRadius: '50%',
            textAlign: 'center',
            lineHeight: '20px',
            fontSize: '14px',
            width: '18px',
            height: '18px',
            cursor: 'pointer',
            display: 'inline-block',
            marginLeft: '5px',
        };

        const infoTextStyle = {
            position: 'absolute',
            top: '25px',
            left: '0',
            backgroundColor: 'white',
            border: '2px solid #120c34',
            borderRadius: '25px',
            padding: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '400px',
            zIndex: '10',
            display: this.state.showInfo ? 'block' : 'none',
        };

        const textStyle = {
            margin: '0',
            fontSize: '14px',
            color: '#333',
        };

        return (
            <div style={cardStyle}>
                <div onClick={this.toggleInfo}>
                <img src="/library/info.svg" alt="Image" style={{ width: '18px', height: '18px', backgroundSize: 'cover' }} />
                </div>
                <div style={infoTextStyle}>
                    <p style={textStyle}>{this.props.infoText}</p>
                </div>
            </div>
        );
    }
}

export default InfoCard;
