import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Header = () => (
  <nav className="navbar navbar-expand-lg navbar-light" style={{ width: "100%" }} >
      <div className="container-fluid " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="d-flex justify-content-center align-items-center w-100">
              <Link to="/wallet" className="navbar-brand d-none d-lg-block me-3">
                  <button className="btn btn-lg lead" style={{
                      fontFamily: "Inria Serif",
                      backgroundColor: "rgba(232, 232, 232, 1)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "200px",
                      color: "black",
                      textDecoration: "none"
                  }}>
                      <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 0.00</p>
                  </button>
              </Link>

              <Link to="/" className="navbar-brand mx-auto" href="/">
                  <img src="/library/AADDYY_Console.png" alt="" style={{ height: "10vh" }} />
              </Link>



              <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                  <span className="navbar-toggler-icon"></span>
              </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto">
                  <li className="nav-item d-lg-none">
                      <Link to="/wallet" className="nav-link">
                          <button className="btn btn-lg lead" style={{
                              fontFamily: "Inria Serif",
                              backgroundColor: "rgba(232, 232, 232, 1)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "200px",
                              color: "black",
                              textDecoration: "none"
                          }}>
                              <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 0.00</p>
                          </button>
                      </Link>
                  </li>
                  <li className="nav-item">
                      <Link to="/login" className="nav-link">
                          <button className="btn btn-lg lead " style={{
                              fontFamily: "Inria Serif",
                              backgroundColor: "rgba(232, 232, 232, 1)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "200px",
                              color: "black",
                              textDecoration: "none"
                          }}>
                              <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i class="fa-regular fa-user mr-2"></i>Log In</p>
                          </button>
                      </Link>
                  </li>
              </ul>
          </div>
      </div>
  </nav>
);

const ImageAI = () => {
  const [image, setImage] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result.split(',')[1]); // Get base64 string without the prefix
    };
    reader.readAsDataURL(file);
  };

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const apiKey = 'sk-proj-0YgHw5lHJFDlvPaBVSWCT3BlbkFJqXXsOWbiY8FUSCnDmmtb';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    };

    const payload = {
      model: 'gpt-4o-mini',
      messages: [
        {
          role: 'user',
          content: [
            { type: 'text', text: prompt },
            {
              type: 'image_url',
              image_url: {
                url: `data:image/jpeg;base64,${image}`,
              },
            },
          ],
        },
      ],
      max_tokens: 300
    };

    try {
      const response = await axios.post('https://api.openai.com/v1/chat/completions', payload, { headers });
      setResponse(response.data.choices[0].message.content);
    } catch (error) {
      console.error('Error:', error);
      setResponse('An error occurred while processing your request.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <Header/>
    <div className="container">
      <div className="row">
        <div className="col-md-6 ">
          <div className="form-group">
            <div
              style={{
                border: '2px dashed #ccc',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                height: '30vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => document.getElementById('imageUpload').click()}
            >
              {image ? (
                <img
                  src={`data:image/jpeg;base64,${image}`}
                  alt="Preview"
                  style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                />
              ) : (
                <>
                <i className="fa-solid fa-image fa-10x"></i>
                <h5 style={{marginTop: "10px",fontFamily: "Inria Serif"}}>Click to upload an image</h5>
                </>
              )}
            </div>
            <input
              type="file"
              className="form-control"
              id="imageUpload"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="promptInput" style={{fontFamily: "Inria Serif",fontWeight: "bold"}}>Prompt</label>
            <textarea
                className="form-control border-0"
                rows="6"
                placeholder="Write a prompt here to begin your creativity"
                value={prompt}
                style={{ fontFamily: 'Inria Serif', fontSize: '1.1rem', fontWeight: '400', borderRadius: '10px' }}
                onChange={handlePromptChange}
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn btn-outline-dark w-100"
            style={{
                transition: 'all 0.3s',
                fontFamily: "Inria Serif",
                fontSize: '1.5rem',
                fontWeight: '400',
                borderRadius: '10px',
                backgroundColor: "white",
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(232, 232, 232, 1)'}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Submit'}
          </button>
        </div>
        <div className="col-md-6">
          <h3 style={{fontFamily: "Inria Serif",fontWeight: "bold"}} className='text-center'>RESPONSE</h3>
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p style={{fontFamily: "Inria Serif", marginTop: "10px"}}>Generating response...</p>
            </div>
          ) : (
            <p className='text-justify' style={{fontFamily: "Inria Serif"}}>{response}</p>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default ImageAI;
