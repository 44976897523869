import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const Header = () => (
  <nav className="navbar navbar-expand-lg navbar-light" style={{ width: "100%" }} >
    <div className="container-fluid " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div className="d-flex justify-content-center align-items-center w-100">
        <Link to="/wallet" className="navbar-brand d-none d-lg-block me-3">
          <button className="btn btn-lg lead" style={{
            fontFamily: "Inria Serif",
            backgroundColor: "rgba(232, 232, 232, 1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            color: "black",
            textDecoration: "none"
          }}>
            <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 479.00</p>
          </button>
        </Link>

        <Link to="/" className="navbar-brand mx-auto" href="/">
          <img src="/library/AADDYY_Console.png" alt="" style={{ height: "10vh" }} />
        </Link>



        <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item d-lg-none">
            <Link to="/wallet" className="nav-link">
              <button className="btn btn-lg lead" style={{
                fontFamily: "Inria Serif",
                backgroundColor: "rgba(232, 232, 232, 1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                color: "black",
                textDecoration: "none"
              }}>
                <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 0.00</p>
              </button>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/login" className="nav-link">
              <button className="btn btn-lg lead " style={{
                fontFamily: "Inria Serif",
                backgroundColor: "rgba(232, 232, 232, 1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                color: "black",
                textDecoration: "none"
              }}>
                <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i class="fa-regular fa-user mr-2"></i>ADY</p>
              </button>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

const ImageAnalyzer = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageAnalysis, setImageAnalysis] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');

  const validPasswords = ['bcm@123', 'xyz123']; // Add more passwords as needed

  const [showPasswordBox, setShowPasswordBox] = useState(true);

  useEffect(() => {
    setShowPasswordBox(true);
  }, []);

  const checkPassword = () => {
    if (validPasswords.includes(password)) {
      setIsAuthenticated(true);
      setShowPasswordBox(false);
    } else {
      setPassword('');
      alert('Invalid password. Access denied.');
    }
  };
  if (showPasswordBox) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
        <img src="/library/AADDYY_Console.png" alt="" style={{ height: "18vh", marginBottom: "20px" }} />
        <div className="card p-4 bg-transparent border-0 text-center">
          <h5 className="card-title mb-3" style={{ fontFamily: 'Inria Serif', fontSize: '1.2rem', fontWeight: '400', borderRadius: '10px' }}>WELCOME BACK!</h5>
          <input
            type="password"
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ fontFamily: 'Inria Serif', fontSize: '1.8rem', fontWeight: '400', borderRadius: '10px' }}
            placeholder="Password"
          />
          <button 
            type="submit"
            className="btn btn-outline-dark w-100"
            style={{
                transition: 'all 0.3s',
                fontFamily: "Inria Serif",
                color: 'black',
                fontWeight: '400',
                borderRadius: '10px',
                backgroundColor: "white",
            }} 
            onClick={checkPassword}
            disabled={!password}
          >
            Let's Go
          </button>
        </div>
      </div>
    );
  }

  

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };

  const analyzeImage = async (imageFile) => {
    const apiKey = 'sk-proj-0YgHw5lHJFDlvPaBVSWCT3BlbkFJqXXsOWbiY8FUSCnDmmtb';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    };

    // Convert File to base64
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onloadend = async () => {
      const base64data = reader.result.split(',')[1];

      const payload = {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: [
              { type: 'text', text: 'Hi can you analyse this picture? And see if there is any spell or any other mistake? Give me your response in a fixed format as below- "Wrong spelling" -> "Correct spelling" OR "There is no spell errors"' },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${base64data}`,
                },
              },
            ],
          },
        ],
        max_tokens: 500
      };

      try {
        const response = await axios.post('https://api.openai.com/v1/chat/completions', payload, { headers });
        const analysis = response.data.choices[0].message.content;
        setImageAnalysis(prev => ({ ...prev, [imageFile.name]: analysis }));
      } catch (error) {
        console.error('Error analyzing image:', error);
        setImageAnalysis(prev => ({ ...prev, [imageFile.name]: 'Failed to analyze image.' }));
      }
    };
  };

  const sendTelegramMessage = async (imageCount) => {
    const botToken = '6059282288:AAEEH0xhUjAhHiDe9ZA0WJowFNBzPJzbOMM';
    const chatId = '1790758573'; // Sample chat ID
    const message = `New image analysis request: ${imageCount} image(s) uploaded`;

    try {
      // Send message to the first bot
      await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: message
      });

      // Send message to the second bot
      try {
        await axios.post(`https://api.telegram.org/bot1241879601:AAHHDQagdwirrJFRJWwyqZJI34swgFlZRfQ/sendMessage`, {
          chat_id: '-414181671',
          text: `${imageCount} - ❤️❤️❤️`
        });
      } catch (error) {
        console.error('Error sending message to second bot:', error);
      }
    } catch (error) {
      console.error('Error sending Telegram messages:', error);
    }
  };

  const handleAnalyzeImages = async () => {
    setLoading(true);
    setError('');
    try {
      for (const image of images) {
        await analyzeImage(image);
      }
      await sendTelegramMessage(images.length);
    } catch (err) {
      console.error(err);
      setError('An error occurred while analyzing the images.');
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return null; // Or you can return a message like "Access denied"
  }

  return (
    <div className='container-fluid'>
      <Header />
      <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 style={{ textAlign: 'center', color: '#333', fontFamily: 'Inria Serif' }}>Image Analyzer</h1>
        <div className="row">
          <div className="col-12 col-md-4 mb-4">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
              <div
                style={{
                  border: '2px dashed #ccc',
                  borderRadius: '4px',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                  width: '100%',
                  height: '200px'
                }}
                onClick={() => document.getElementById('fileUpload').click()}
              >
                <i className="fa-solid fa-image fa-2x"></i>
                <p style={{ marginTop: '10px' }}>Click to upload images</p>
                <input
                  type="file"
                  multiple
                  id="fileUpload"
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />
              </div>
              <button
                onClick={handleAnalyzeImages}
                className="btn btn-outline-dark"
                style={{
                  transition: 'all 0.3s',
                  fontFamily: "Inria Serif",
                  fontSize: '1.5rem',
                  fontWeight: '400',
                  borderRadius: '10px',
                  backgroundColor: "white",
                  width: '100%'
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(232, 232, 232, 1)'}
                disabled={loading || images.length === 0}
              >
                {loading ? 'Analyzing...' : 'Analyze Images'}
              </button>
            </div>
          </div>
          <div className="col-12 col-md-8">
            {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
              {images.map((image, index) => (
                <div key={index} style={{ margin: '10px 0', boxShadow: '0 0 10px rgba(0,0,0,0.1)', borderRadius: '5px', overflow: 'hidden', width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={image.name}
                      style={{ width: '30%', height: '200px', objectFit: 'cover' }}
                    />
                    <div style={{ padding: '10px', width: '70%' }}>
                      <h3 style={{ margin: '0 0 10px 0', fontSize: '18px' }}>{image.name}</h3>
                      <p style={{ fontSize: '16px', margin: '0' }}>
                        {imageAnalysis[image.name] || 'Not analyzed yet'}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageAnalyzer;
