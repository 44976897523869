import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InfoCard from './InfoCard';

const Header = () => (
    <nav className="navbar navbar-expand-lg navbar-light" style={{ width: "100%" }} >
        <div className="container-fluid " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="d-flex justify-content-center align-items-center w-100">
                <Link to="/wallet" className="navbar-brand d-none d-lg-block me-3">
                    <button className="btn btn-lg lead" style={{
                        fontFamily: "Inria Serif",
                        backgroundColor: "rgba(232, 232, 232, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                        color: "black",
                        textDecoration: "none"
                    }}>
                        <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 0.00</p>
                    </button>
                </Link>

                <Link to="/" className="navbar-brand mx-auto" href="/">
                    <img src="/library/AADDYY_Console.png" alt="" style={{ height: "10vh" }} />
                </Link>



                <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item d-lg-none">
                        <Link to="/wallet" className="nav-link">
                            <button className="btn btn-lg lead" style={{
                                fontFamily: "Inria Serif",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "200px",
                                color: "black",
                                textDecoration: "none"
                            }}>
                                <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 0.00</p>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/login" className="nav-link">
                            <button className="btn btn-lg lead " style={{
                                fontFamily: "Inria Serif",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "200px",
                                color: "black",
                                textDecoration: "none"
                            }}>
                                <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i class="fa-regular fa-user mr-2"></i>Log In</p>
                            </button>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
);

const DropdownComponent = ({ onSelect }) => {
    const [selectedOption, setSelectedOption] = useState('Generate Anything');

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSelect(option);
    };

    return (
        <div className="dropdown mb-3 w-100">
            <button className="btn btn-secondary dropdown-toggle w-100 d-flex justify-content-between align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ borderWidth: '1px', backgroundColor: 'white', color: 'black', borderRadius: '10px' }}>
                <span className="w-100 text-center" style={{ fontFamily: "Inria Serif", fontSize: '1.5rem', fontWeight: '400' }}>{selectedOption}</span>
            </button>
            <ul className="dropdown-menu w-100 p-3" aria-labelledby="dropdownMenuButton" style={{ fontFamily: "Inria Serif", fontSize: '1.5rem', fontWeight: '400',backgroundColor: "rgba(232, 232, 232, 1)",borderRadius: '10px' }}>
                <li>
                    <button
                        className="dropdown-item"
                        type="button"
                        style={{ fontFamily: "Inria Serif", fontSize: '1.5rem', fontWeight: '400', display: 'flex', alignItems: 'center' }}
                        onClick={() => handleSelect('Jewellery')}>
                        <i className="fa-regular fa-gem" style={{ marginRight: '15px', width: '20px', textAlign: 'center' }}></i>
                        <span>Jewellery</span>
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item"
                        type="button"
                        style={{ fontFamily: "Inria Serif", fontSize: '1.5rem', fontWeight: '400', display: 'flex', alignItems: 'center' }}
                        onClick={() => handleSelect('Architecture')}>
                        <i className="fa-regular fa-building" style={{ marginRight: '12px', width: '20px', textAlign: 'center' }}></i>
                        <span>Architecture</span>
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item"
                        type="button"
                        style={{ fontFamily: "Inria Serif", fontSize: '1.5rem', fontWeight: '400', display: 'flex', alignItems: 'center' }}
                        onClick={() => handleSelect('Clothing')}>
                        <i className="fa-solid fa-shirt" style={{ marginRight: '15px', width: '20px', textAlign: 'center' }}></i>
                        <span>Clothing</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

const ImageSize_Dropdown = ({ onSizeChange, onDimensionsChange }) => {
    const [selectedOption, setSelectedOption] = useState('Square HD');
    const [dimensions, setDimensions] = useState({ width: 1024, height: 1024 });
    const [isCustom, setIsCustom] = useState(false);

    const handleSizeChange = (selectedSize) => {
        setSelectedOption(selectedSize);
        onSizeChange(selectedSize);
        if (selectedSize === 'Square HD') {
            setDimensions({ width: 1024, height: 1024 });
            setIsCustom(false);
        } else if (selectedSize === 'Landscape HD') {
            setDimensions({ width: 2048, height: 1024 });
            setIsCustom(false);
        } else if (selectedSize === 'Portrait HD') {
            setDimensions({ width: 1024, height: 2048 });
            setIsCustom(false);
        } else if (selectedSize === 'Custom') {
            setIsCustom(true);
        }
        onDimensionsChange(dimensions);
    };
    const handleSelect = (option) => {
        setSelectedOption(option);
        handleSizeChange(option);
    };

    const handleCustomDimensionChange = (type, value) => {
        const newDimensions = { ...dimensions, [type]: parseInt(value) || 0 };
        setDimensions(newDimensions);
        onDimensionsChange(newDimensions);
    };

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-6">
                    <div className="dropdown mb-3 w-100">
                        <button className="btn btn-outline-dark dropdown-toggle w-100 d-flex justify-content-between align-items-center border-0"
                            type="button"
                            id="dropdownGenerateButton1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ borderWidth: '1px', backgroundColor: 'white', color: 'black', borderRadius: '10px', backgroundColor: "rgba(232, 232, 232, 1)", }}>
                            <span className="w-100 text-center" style={{ fontFamily: "Inria Serif", fontSize: '1.5rem', fontWeight: '400' }}>{selectedOption}</span>
                        </button>
                        <ul className="dropdown-menu w-100 p-3" aria-labelledby="dropdownGenerateButton1"style={{ fontFamily: "Inria Serif", fontSize: '1.5rem', fontWeight: '400',backgroundColor: "rgba(232, 232, 232, 1)",borderRadius: '10px' }}>
                            <li>
                                <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => handleSelect('Square HD')}>
                                    Square HD
                                </button>
                            </li>
                            <li>
                                <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => handleSelect('Landscape HD')}>
                                    Landscape HD
                                </button>
                            </li>
                            <li>
                                <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => handleSelect('Portrait HD')}>
                                    Portrait HD
                                </button>
                            </li>
                            <li>
                                <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => handleSelect('Custom')}>
                                    Custom
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-6">
                    <div className='d-flex justify-content-center align-items-center' style={{ gap: '10px' }}>
                        <input
                            type="number"
                            className="form-control border-0 me-2 text-center"
                            placeholder="Width"
                            value={dimensions.width}
                            readOnly={!isCustom}
                            onChange={(e) => handleCustomDimensionChange('width', e.target.value)}
                            style={{
                                fontFamily: "Inria Serif",
                                fontSize: '1.5rem',
                                fontWeight: '400',
                                borderWidth: '1px',
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                color: 'black',
                                borderRadius: '10px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        />
                        <p style={{ fontSize: '1.2rem', fontWeight: '400', margin: '0' }}>X</p>
                        <input
                            type="number"
                            className="form-control border-0 text-center"
                            placeholder="Height"
                            value={dimensions.height}
                            readOnly={!isCustom}
                            onChange={(e) => handleCustomDimensionChange('height', e.target.value)}
                            style={{
                                fontFamily: "Inria Serif",
                                fontSize: '1.5rem',
                                fontWeight: '400',
                                borderWidth: '1px',
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                color: 'black',
                                borderRadius: '10px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const CustomSlider = ({ initialValue, onChange }) => {
    const [value, setValue] = useState(initialValue);

    const handleSliderChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        onChange(newValue);
    };

    const handleInputChange = (e) => {
        let newValue = e.target.value;
        newValue = Math.max(0, Math.min(100, Number(newValue) || 0));
        setValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="">
            <div className="mb-2">
                <div className="d-flex justify-content-center align-items-center">
                    <p className="lead text-center mb-2 mt-1 mr-2" style={{ fontFamily: "Inria Serif" }}>Closeness to Prompt</p>
                    <img src="/library/info.svg" alt="Image" style={{ width: '18px', height: '18px', backgroundSize: 'cover' }} />
                </div>
                {/* <p className="lead text-center mb-2 mt-1" style={{ fontFamily: "Inria Serif" }}>Closeness to Prompt</p> */}
            </div>
            <div className="row">
                <div className="col-9 ">
                    <div className="position-relative" style={{ height: '50px' }}>
                        <div className="position-absolute w-100 h-100  rounded" style={{ borderRadius: '10px', background: "rgba(232, 232, 232, 1)" }}></div>
                        <div
                            className="position-absolute h-100 bg-secondary rounded"
                            style={{ width: `${value}%`, borderRadius: '15px' }}
                        ></div>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={value}
                            onChange={handleSliderChange}
                            className="position-absolute w-100 h-100 cursor-pointer "
                            style={{ appearance: 'none', background: 'transparent', borderRadius: "15px" }}
                        />
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative" style={{ height: '50px' }}>
                        <input
                            type="number"
                            value={value}
                            onChange={handleInputChange}
                            className="form-control h-100 text-center"
                            style={{
                                fontFamily: "Inria Serif",
                                fontSize: '1.5rem',
                                fontWeight: '400',
                                borderWidth: '1px',
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                color: 'black',
                                borderRadius: '10px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50px',
                                paddingRight: '30px'  // Make room for the percentage symbol
                            }}
                            min="0"
                            max="100"
                        />
                        <div
                            className="position-absolute"
                            style={{
                                right: '50px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                pointerEvents: 'none'  // Ensures the div doesn't interfere with input
                            }}
                        >
                            %
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const GenerateForm = ({ setGeneratedImageUrl }) => {
    const [formData, setFormData] = useState({
        dropdownOption: 'Generate Anything',
        prompt: '',
        size: 'Square HD',
        dimensions: { width: 1024, height: 1024 },
        closeness: 35
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleDropdownSelect = (option) => {
        setFormData(prevData => ({ ...prevData, dropdownOption: option }));
    };

    const handlePromptChange = (e) => {
        setFormData(prevData => ({ ...prevData, prompt: e.target.value }));
    };

    const handleSizeChange = (selectedSize) => {
        setFormData(prevData => ({ ...prevData, size: selectedSize }));
    };

    const handleDimensionsChange = (newDimensions) => {
        setFormData(prevData => ({ ...prevData, dimensions: newDimensions }));
    };

    const handleClosenessChange = (newValue) => {
        setFormData(prevData => ({ ...prevData, closeness: newValue }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        setIsLoading(true);
        try {
            const response = await fetch('http://127.0.0.1:8000/custom_photo_api/api/ady/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('Response from Django API:', data);
                if (data.image) {
                    setGeneratedImageUrl(data.image);
                }
            } else {
                console.error('Error from Django API:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className='container-fluid' style={{ width: '80%' }}>
            <form onSubmit={handleSubmit}>
                <DropdownComponent onSelect={handleDropdownSelect} />

                <div className="mb-3 text-center">
                    <div className="d-flex justify-content-center align-items-center">
                        <p className="lead text-center mb-2 mt-1 mr-2" style={{ fontFamily: "Inria Serif" }}>Write a prompt here to begin your creativity</p>
                        <img src="/library/info.svg" alt="Image" style={{ width: '18px', height: '18px', backgroundSize: 'cover' }} />
                    </div>
                    <textarea
                        className="form-control border-0"
                        rows="6"
                        placeholder="Write a prompt here to begin your creativity"
                        value={formData.prompt}
                        style={{ fontFamily: 'Inria Serif', fontSize: '1.1rem', fontWeight: '400', borderRadius: '10px' }}
                        onChange={handlePromptChange}
                    ></textarea>
                </div>

                <div className="mb-3">
                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src="/library/star.svg" alt="Image" style={{ width: '20px', height: '20px', backgroundSize: 'cover' }} />
                        <small className="form-text text-dark" >
                            <u>Add image to my prompt</u>
                        </small>
                    </div>
                </div>
                <hr style={{ border: '1px solid black', width: '100%' }} />

                <div className="mb-3 text-center">
                    <div className="d-flex justify-content-center align-items-center">
                        <p className="lead text-center mb-2 mt-1 mr-2" style={{ fontFamily: "Inria Serif" }}>Dimensions</p>
                        <InfoCard infoText="Your brand Embassy is an ongoing program in which your ambassadors are componsated per click for traffic they drive to a custom url." />
                    </div>

                    <ImageSize_Dropdown onSizeChange={handleSizeChange} onDimensionsChange={handleDimensionsChange} />

                </div>

                <div className="pb-3">
                    <CustomSlider initialValue={formData.closeness} onChange={handleClosenessChange} />
                </div>

                <div className="col-12 text-center mt-3">
                    <button
                        type="submit"
                        className="btn btn-outline-dark w-50"
                        style={{
                            transition: 'all 0.3s',
                            fontFamily: "Inria Serif",
                            fontSize: '1.5rem',
                            fontWeight: '400',
                            borderRadius: '10px',
                            backgroundColor: "white",
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(232, 232, 232, 1)'}
                    >
                        Create
                    </button>
                </div>
            </form>
        </div>
    );
};

const ImagePreview = ({ imageUrl, isLoading }) => (
    <div className="position-relative text-center">
        {isLoading ? (
            <div className="loader" style={{
                width: '60px',
                aspectRatio: '1',
                '--c': 'no-repeat linear-gradient(#046D8B 0 0)',
                background: 'var(--c),var(--c),var(--c),var(--c)',
                animation: 'l9-1 1.5s infinite, l9-2 1.5s infinite',
                margin: 'auto'
            }}></div>
        ) : (
            <img 
                src={imageUrl || "/library/1.png"} 
                alt="Generated Image" 
                className="img-fluid" 
                style={{ width: '80%', height: '80%', objectFit: 'contain', borderRadius: '10px' }} 
            />
        )}
    </div>
);

const App = () => {
    const [generatedImageUrl, setGeneratedImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <div className="container-fluid ">
                <Header />
                <p className="lead text-center mb-1 mt-1" style={{ fontFamily: "Inria Serif" }}>Create your own graphics by defining the attention</p>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <GenerateForm setGeneratedImageUrl={setGeneratedImageUrl} setIsLoading={setIsLoading} />
                    </div>
                    <div className="col-md-6">
                        <ImagePreview imageUrl={generatedImageUrl} isLoading={isLoading} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default App;