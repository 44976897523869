import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const Connect = () => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [currentImageIndices, setCurrentImageIndices] = useState([0, 1]);
  const [fade, setFade] = useState(true); // Controls the fade-in and fade-out effect

  // This would typically be fetched from the server or read dynamically
  const imageFiles = [
    '1.png',
    '2.png',
    '3.png',
    '4.png',
    '5.png',
    '6.png',

    // Add more image filenames as needed
  ];

  useEffect(() => {
    // Simulate initial loading
    const timer = setTimeout(() => {
      setLoading(false);
      setImages(imageFiles.map(file => `/library/${file}`));
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Rotate images every 3 seconds with a fade effect
    const rotateInterval = setInterval(() => {
      // Start fade-out
      setFade(false);

      setTimeout(() => {
        // After fade-out, update the images and start fade-in
        setCurrentImageIndices(prevIndices => {
          return prevIndices.map(index => (index + 2) % images.length);
        });
        setFade(true); // Start fade-in after changing images
      }, 500); // 500ms matches the CSS transition time for fade-out
    }, 5000);

    return () => clearInterval(rotateInterval);
  }, [images]);

  if (loading) {
    return (
      <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#fffcf6" }}>
        <img src="/library/logo1.png" alt="AADDYY Logo" className="img-load-view" style={{ width: "10%" }} />
      </div>
    );
  }

  return (
    <div className="container-fluid py-2" style={{width:"90%"}}>
      <div className="text-center">
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand mx-auto d-lg-none" href="/">
            <img src="/library/logo1.png" alt="" style={{ height: "10vh" }} />
          </a>
          <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
            <ul className="navbar-nav align-items-center">
            <li className="nav-item">
                <Link to="/contact" className="nav-link lead text-center" style={{ fontFamily: "Inria Serif", color: "black", textDecoration: "none" }}>
                  <p style={{fontSize:"1.1rem", margin: 0}}><u>CONTACT</u></p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/work" className="nav-link lead text-center" style={{ fontFamily: "Inria Serif", color: "black", textDecoration: "none" }}>
                  <p style={{fontSize:"1.1rem", margin: 0}}><u>OUR WORK</u></p>
                </Link>
              </li>
              <li className="nav-item d-none d-lg-block">
                <Link to="/" className="navbar-brand mx-lg-5 mx-0" href="/">
                  <img src="/library/logo1.png" alt="" style={{ height: "10vh" }} />
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/console" className="nav-link lead text-center" style={{ fontFamily: "Inria Serif", color: "black", textDecoration: "none" }}>
                  <p style={{fontSize:"1.1rem", margin: 0}}><u>CONSOLE</u></p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/privacy" className="nav-link lead text-center" style={{ fontFamily: "Inria Serif", color: "black", textDecoration: "none" }}>
                  <p style={{fontSize:"1.1rem", margin: 0}}><u>PRIVACY</u></p>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* <p className="lead text-center mb-2 mt-1" style={{ fontFamily: "Inria Serif" }}>AI Boutique for Premium graphics & Fashion Designing</p> */}
      </div>


      <p className="lead text-center mb-3" style={{ fontFamily: "Inria Serif" }}>Connect with Boutique</p>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-outline-dark mx-2 border-0"
          style={{
            fontFamily: "Inria Serif",
            backgroundColor: "rgba(232, 232, 232, 1)",
            display: "flex",
            alignItems: "center",
            gap: "10px"
          }}
          onClick={() => window.location.href = 'mailto:aaddyygraphics@gmail.com'}
        >
          <i className="fa-regular fa-envelope"></i> Email
        </button>

        <a
          className="btn btn-outline-dark mx-2 border-0"
          href='https://www.instagram.com/aaddyy.official/'
          style={{
            fontFamily: "Inria Serif",
            backgroundColor: "rgba(232, 232, 232, 1)",
            display: "flex",
            alignItems: "center",
            gap: "10px"
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-instagram"></i> Instagram
        </a>
      </div>
    </div>
  );
};

export default Connect;