import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loading from './Animation/loading';

const formatEvaluation = (evaluation) => {
    // Split the evaluation by new lines
    const lines = evaluation.split('\n').filter(line => line.trim() !== '');
  
    // Parse the lines into JSX
    return lines.map((line, index) => {
      // Remove leading numbers followed by a colon and a space (e.g., 1: , 2: )
      const cleanedLine = line.replace(/^\d+.\s*/, '');
  
      // Check if the line starts with ###
      if (cleanedLine.startsWith('###')) {
        return <h4 className='text-center' key={index} style={{ marginBottom: '16px' }}>{cleanedLine.replace('### ', '')}</h4>;
      }
  
      // Process lines with potential **text** for h4 within a p tag
      const parts = [];
      let remainingText = cleanedLine;
  
      // Loop to extract all **Text** occurrences
      let match = remainingText.match(/\*\*(.*?)\*\*/);
      while (match) {
        // Extract text before **Text**
        const beforeText = remainingText.substring(0, match.index);
        if (beforeText) parts.push(<p key={`p-${index}-${parts.length}`} style={{ marginBottom: '16px' }}>{beforeText}</p>);
  
        // Extract the text inside **
        const h4Text = match[1];
        parts.push(<h4 key={`h4-${index}-${parts.length}`} style={{ marginBottom: '16px' }}>{h4Text}</h4>);
  
        // Update the remaining text
        remainingText = remainingText.substring(match.index + match[0].length);
        match = remainingText.match(/\*\*(.*?)\*\*/);
      }
  
      // Add remaining text after last **Text**
      if (remainingText) {
        parts.push(<p key={`p-last-${index}`} style={{ marginBottom: '16px' }}>{remainingText.trim()}</p>);
      }
  
      // Return the combined parts (h4s and paragraphs)
      return <div key={index}>{parts}</div>;
    });
  };
  
  
const Header = () => (
    <nav className="navbar navbar-expand-lg navbar-light" style={{ width: "100%" }} >
        <div className="container-fluid " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="d-flex justify-content-center align-items-center w-100">
                <Link to="/wallet" className="navbar-brand d-none d-lg-block me-3">
                    <button className="btn btn-lg lead" style={{
                        fontFamily: "Inria Serif",
                        backgroundColor: "rgba(232, 232, 232, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                        color: "black",
                        textDecoration: "none"
                    }}>
                        <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 479.00</p>
                    </button>
                </Link>

                <Link to="/" className="navbar-brand mx-auto" href="/">
                    <img src="/library/AADDYY_Console.png" alt="" style={{ height: "10vh" }} />
                </Link>



                <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item d-lg-none">
                        <Link to="/wallet" className="nav-link">
                            <button className="btn btn-lg lead" style={{
                                fontFamily: "Inria Serif",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "200px",
                                color: "black",
                                textDecoration: "none"
                            }}>
                                <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i className="fa-solid fa-wallet"></i> $ 0.00</p>
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/login" className="nav-link">
                            <button className="btn btn-lg lead " style={{
                                fontFamily: "Inria Serif",
                                backgroundColor: "rgba(232, 232, 232, 1)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "200px",
                                color: "black",
                                textDecoration: "none"
                            }}>
                                <p style={{ textDecoration: "none", fontSize: "1.1rem", margin: 0 }}><i class="fa-regular fa-user mr-2"></i>ADY</p>
                            </button>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
);

const Company_ImageAI = () => {
    const [companyLogo, setCompanyLogo] = useState(null);
    const [companyColor, setCompanyColor] = useState(null);
    const [companyFont, setCompanyFont] = useState(null);
    const [image, setImage] = useState(null);
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const validPasswords = ['bcm@123', 'xyz123']; // Add more passwords as needed
    const [showPasswordBox, setShowPasswordBox] = useState(true);

    useEffect(() => {
        setShowPasswordBox(true);
    }, []);

    const checkPassword = () => {
        if (validPasswords.includes(password)) {
            setIsAuthenticated(true);
            setShowPasswordBox(false);
        } else {
            setPassword('');
            alert('Invalid password. Access denied.');
        }
    };

    const handleFileUpload = (event, setter) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setter(reader.result.split(',')[1]); // Get base64 string without the prefix
        };
        reader.readAsDataURL(file);
    };

    const handlePromptChange = (event) => {
        setPrompt(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const apiKey = 'sk-proj-0YgHw5lHJFDlvPaBVSWCT3BlbkFJqXXsOWbiY8FUSCnDmmtb';
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
        };

        const newMessages = [
            {
                role: 'user',
                content: [
                    { type: 'text', text: 'Company Logo:' },
                    { type: 'image_url', image_url: { url: `data:image/jpeg;base64,${companyLogo}` } },
                    { type: 'text', text: 'Company Color:' },
                    { type: 'image_url', image_url: { url: `data:image/jpeg;base64,${companyColor}` } },
                    { type: 'text', text: 'Company Font:' },
                    { type: 'image_url', image_url: { url: `data:image/jpeg;base64,${companyFont}` } },
                ],
            },
            {
                role: 'user',
                content: [
                    { type: 'text', text: prompt },
                    { type: 'image_url', image_url: { url: `data:image/jpeg;base64,${image}` } },
                ],
            },
        ];

        const payload = {
            model: 'gpt-4o-mini',
            messages: [...messages, ...newMessages],
            max_tokens: 900
        };

        try {
            const response = await axios.post('https://api.openai.com/v1/chat/completions', payload, { headers });
            const newResponse = response.data.choices[0].message.content;
            setResponse(newResponse);
            setMessages([...messages, ...newMessages, { role: 'assistant', content: newResponse }]);
        } catch (error) {
            console.error('Error:', error);
            setResponse('An error occurred while processing your request.');
        } finally {
            setIsLoading(false);
        }
    };

    if (showPasswordBox) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
                <img src="/library/AADDYY_Console.png" alt="" style={{ height: "18vh", marginBottom: "20px" }} />
                <div className="card p-4 bg-transparent border-0 text-center">
                    <h5 className="card-title mb-3" style={{ fontFamily: 'Inria Serif', fontSize: '1.2rem', fontWeight: '400', borderRadius: '10px' }}>WELCOME BACK!</h5>
                    <input
                        type="password"
                        className="form-control mb-3"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ fontFamily: 'Inria Serif', fontSize: '1.8rem', fontWeight: '400', borderRadius: '10px' }}
                        placeholder="Password"
                    />
                    <button 
                        type="submit"
                        className="btn btn-outline-dark w-100"
                        style={{
                            transition: 'all 0.3s',
                            fontFamily: "Inria Serif",
                            color: 'black',
                            fontWeight: '400',
                            borderRadius: '10px',
                            backgroundColor: "white",
                        }} 
                        onClick={checkPassword}
                        disabled={!password}
                    >
                        Let's Go
                    </button>
                </div>
            </div>
        );
    }

    return (
        <>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group mb-3">
                                    <label htmlFor="companyLogoUpload" style={{ fontFamily: "Inria Serif", fontWeight: "bold" }}>Company Logo</label>
                                    <div
                                        style={{
                                            border: '2px dashed #ccc',
                                            borderRadius: '4px',
                                            padding: '20px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            height: '20vh',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => document.getElementById('companyLogoUpload').click()}
                                    >
                                        {companyLogo ? (
                                            <img
                                                src={`data:image/jpeg;base64,${companyLogo}`}
                                                alt="Company Logo Preview"
                                                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                                            />
                                        ) : (
                                            <>
                                            <i className="fa-solid fa-image fa-5x"></i>
                                            {/* <h5 style={{marginTop: "10px",fontFamily: "Inria Serif"}}>Click to upload company logo</h5> */}
                                            </>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="companyLogoUpload"
                                        onChange={(e) => handleFileUpload(e, setCompanyLogo)}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group mb-3">
                                    <label htmlFor="companyColorUpload" style={{ fontFamily: "Inria Serif", fontWeight: "bold" }}>Company Color</label>
                                    <div
                                        style={{
                                            border: '2px dashed #ccc',
                                            borderRadius: '4px',
                                            padding: '20px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            height: '20vh',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => document.getElementById('companyColorUpload').click()}
                                    >
                                        {companyColor ? (
                                            <img
                                                src={`data:image/jpeg;base64,${companyColor}`}
                                                alt="Company Color Preview"
                                                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                                            />
                                        ) : (
                                            <>
                                            <i className="fa-solid fa-image fa-5x"></i>
                                            {/* <h5 style={{marginTop: "10px",fontFamily: "Inria Serif"}}>Click to upload company color</h5> */}
                                            </>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="companyColorUpload"
                                        onChange={(e) => handleFileUpload(e, setCompanyColor)}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group mb-3">
                                    <label htmlFor="companyFontUpload" style={{ fontFamily: "Inria Serif", fontWeight: "bold" }}>Company Font</label>
                                    <div
                                        style={{
                                            border: '2px dashed #ccc',
                                            borderRadius: '4px',
                                            padding: '20px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            height: '20vh',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => document.getElementById('companyFontUpload').click()}
                                    >
                                        {companyFont ? (
                                            <img
                                                src={`data:image/jpeg;base64,${companyFont}`}
                                                alt="Company Font Preview"
                                                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                                            />
                                        ) : (
                                            <>
                                            <i className="fa-solid fa-image fa-5x"></i>
                                            {/* <h5 style={{marginTop: "10px",fontFamily: "Inria Serif"}}>Click to upload company font</h5> */}
                                            </>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="companyFontUpload"
                                        onChange={(e) => handleFileUpload(e, setCompanyFont)}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mb-3">
                                    <label htmlFor="imageUpload" style={{ fontFamily: "Inria Serif", fontWeight: "bold" }}>Image</label>
                                    <div
                                        style={{
                                            border: '2px dashed #ccc',
                                            borderRadius: '4px',
                                            padding: '20px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            height: '20vh',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => document.getElementById('imageUpload').click()}
                                    >
                                        {image ? (
                                            <img
                                                src={`data:image/jpeg;base64,${image}`}
                                                alt="Preview"
                                                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                                            />
                                        ) : (
                                            <>
                                            <i className="fa-solid fa-image fa-6x"></i>
                                            <h5 style={{marginTop: "10px",fontFamily: "Inria Serif"}}>Click to upload an image</h5>
                                            </>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="imageUpload"
                                        onChange={(e) => handleFileUpload(e, setImage)}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="promptInput" style={{ fontFamily: "Inria Serif", fontWeight: "bold" }}>Prompt</label>
                            <textarea
                                className="form-control border-0"
                                rows="6"
                                placeholder="Write a prompt here to begin your creativity"
                                value={prompt}
                                style={{ fontFamily: 'Inria Serif', fontSize: '1.1rem', fontWeight: '400', borderRadius: '10px' }}
                                onChange={handlePromptChange}
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-outline-dark w-100"
                            style={{
                                transition: 'all 0.3s',
                                fontFamily: "Inria Serif",
                                fontSize: '1.5rem',
                                fontWeight: '400',
                                borderRadius: '10px',
                                backgroundColor: "white",
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
                            onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(232, 232, 232, 1)'}
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Processing...' : 'Submit'}
                        </button>
                    </div>
                    <div className="col-md-6">
                        <h3 style={{ fontFamily: "Inria Serif", fontWeight: "bold" }} className='text-center'>RESPONSE</h3>
                        {isLoading ? (
                            <div className='text-center'>
                            {/* {formatEvaluation(response)} */}
                            <Loading />
                            <h3 style={{ fontFamily: "Inria Serif", fontWeight: "bold" }}>Working...</h3>
                        </div>
                        ) : (
                            // <html>{response}</html>
                            // <pre>{response}</pre>
                            <div className='text-center'>
                                {formatEvaluation(response)}
                            </div>
                            // <div dangerouslySetInnerHTML={{ __html: response }} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Company_ImageAI;
