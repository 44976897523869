import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Home from './components/Home';
import Connect from './components/Connect';
import Console from './components/Console';
import ImageAI from './components/ImageAI';
import Company_ImageAI from './components/Company_ImageAi';
import Fetch_DriveAI from './components/Fetch_DriveAI';
function App() {
  return (
    <Router>
      <div className="App">
        

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<Connect />} />
          <Route exact path="/console" element={<Console />} />
          <Route exact path="/imageai" element={<ImageAI />} />
          <Route exact path="/company_imageai" element={<Company_ImageAI />} />
          <Route exact path="/AiSpellCheck" element={<Fetch_DriveAI />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
